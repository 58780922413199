/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/watchcharts/cdn@master/css/libs/featherlight.min.css
 * - /gh/watchcharts/cdn@master/css/libs/featherlight.gallery.min.css
 * - /gh/watchcharts/cdn@master/css/libs/datatables.min.css
 * - /gh/watchcharts/cdn@master/css/libs/jquery-ui-slider.min.css
 * - /gh/watchcharts/cdn@master/css/libs/dropzone.min.css
 * - /gh/watchcharts/cdn@master/css/libs/cropper.min.css
 * - /gh/watchcharts/cdn@master/css/bootstrap/bootstrap-5.3.2.min.css
 * - /gh/watchcharts/cdn@master/css/bootstrap/bootstrap-select-1.14.0-beta3.min.css
 * - /gh/watchcharts/cdn@master/css/bootstrap/bootstrap-datepicker.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
